@import 'src/styles/variables';

.label {
  color: $black100;
  text-align: center;
  margin-bottom: 32px;
}

.advantages {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 32px;
}

.advantage {
  display: flex;
  align-items: center;
  gap: 12px;
}

.icon {
  flex-shrink: 0;
}

.advantageLabel {
  font-size: 14px;
  line-height: 160%;
  color: $black100;
}

.button {
  margin-bottom: 32px;
}
